import { http } from './config'
import authHeader from './auth-header';

export default {


    lista_cliente: (filtro, opcao, page, pageSize) => {

        
          // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
          return http.get('/cliente/listagem', { headers: authHeader() , 
            params: {
                'page': page,
                'size': pageSize,
                'filtro': filtro,
                'opcao': opcao
            }
        });
        },

    
}
